import React from 'react';
import styled from 'styled-components';

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '140px',
    // minWidth: '400px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
`;

const Emotion = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 20px;
`;

const EmotionNormalStyles = {
    fontSize: '60px',
    color: '#B9B9B9'
};

const EmotionActiveStyles = {
    fontSize: '60px',
    color: '#31e0a9',
    backgroundColor: '#21B472',
    borderRadius: '5px'
};

class Overview extends React.Component {

    render() {
        const getValue = (data, key) => {
            let keyIndex = data.key.indexOf(key);
            if (keyIndex < 0) {
                return 0;
            }
            return data.value[keyIndex];
        }

        const prepareData = (startId, endId) => {
            let result = [0, 0, 0, 0, 0];
            let i = startId;

            while (i <= endId) {
                result[0] += getValue(this.props.data[i], 'มากที่สุด');
                result[1] += getValue(this.props.data[i], 'มาก');
                result[2] += getValue(this.props.data[i], 'ปานกลาง');
                result[3] += getValue(this.props.data[i], 'น้อย');
                result[4] += getValue(this.props.data[i], 'น้อยที่สุด');
                i++;
            }

            let weight = 5;
            let total = endId - startId + 1;
            result = result.map(x => (x / total) * weight--);
            result = result.reduce((a, b) => a + b, 0);
            result = result / recordCount;

            return result;
        }

        let recordCount = this.props.data[71].value.reduce((a, b) => a + b, 0);

        let score = 0;
        score = prepareData(71, 71);

        const computeStyle = (step, result) => {
            let active = false;
            switch(step){
                case 1:
                    active = result < 1.81;
                    break;
                case 2:
                    active = result < 2.61;
                    break;
                case 3:
                    active = result < 3.41;
                    break;
                case 4:
                    active = result < 4.21;
                    break;
                default:
                    active = result >= 4.21;
            }

            return active ? EmotionActiveStyles : EmotionNormalStyles;
        }
        
        return (
            <div style={itemStyles}>
                <Title>ความพึงพอใจภาพรวมต่อการบริหารแหล่งท่องเที่ยวพื้นที่นาเกลือ</Title>
                <Emotion>
                    <span className="material-icons" style={computeStyle(1, score)}>mood_bad</span>
                    <span className="material-icons" style={computeStyle(2, score)}>sentiment_very_dissatisfied</span>
                    <span className="material-icons" style={computeStyle(3, score)}>sentiment_satisfied</span>
                    <span className="material-icons" style={computeStyle(4, score)}>sentiment_satisfied_alt</span>
                    <span className="material-icons" style={computeStyle(5, score)}>sentiment_very_satisfied</span>
                </Emotion>
            </div>
        );
    }
}

export default Overview;