import React from 'react';
import styled from 'styled-components';
import { Stack, Pivot, PivotItem } from '@fluentui/react';
import { SpinnerComponent } from 'react-element-spinner';
import Objective from './dashboard/objective';
import Factor from './dashboard/factor';
import Age from './dashboard/age';
import ComeBack from './dashboard/comeback';
import Suggest from './dashboard/suggest';
import Hotel from './dashboard/hotel';
import BirthPlace from './dashboard/birthplace';
import Gender from './dashboard/gender';
import Rate from './dashboard/rate';
import Overview from './dashboard/overview';
import background from "./bg.jpg";


// const itemStyles = {
//     alignItems: 'center',
//     background: '#f2f2f2',
//     // color: DefaultPalette.white,
//     display: 'flex',
//     height: '300px',
//     width: '400px',
//     justifyContent: 'center',
// };

const stack1Styles = {
    root: {
        width: `400px`
    },
};

const stack2Styles = {
    root: {
        width: `600px`,
    },
};

const stackStyles = {
    root: {
        // height: "100vh",
        // width: "100%",
        // background: 'red',
    },
};

const stackItemStyles = {
    root: {
        // background: '#F2F2F2',
        // color: 'white',
        // display: 'flex',
        // padding: 40,
    },
};

// const stackWrapperStyles = {
//     backgroundColor: `#000000`,
//     height: `100vh`
// };

const Wrapper = styled.div`
    background-color: #fafafa;
    border-radius: 15px;
    // background-color: red;
    // min-height: 100%;
    // height: 100vh;
    padding: 10px;
    // max-width: 1800px;
    // width: 1800px;
    opacity: 0.935;
`;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.formId = this.props.match.params.id;
        this.state = {
            year: 1,
            step: 0,
            isLoading: false,
        };
    }

    componentDidMount() {
        let y = this.state.year === 1 ? 0 : this.state.year;
        this.props.firebase.database().ref('dmrf/dashboard/' + this.formId + '/' + y).once('value').then(snapshot => {
            if (!snapshot.val()) {
                this.setState({ step: 2 });
                return;
            }
            this.setState({ data: snapshot.val(), step: 1 });
        });
    }

    #changeYear = (item) => {
        let preYear = this.state.year;
        let y = item.props.itemKey === 1 ? 0 : item.props.itemKey;
        this.setState({ isLoading: true, year: item.props.itemKey });
        this.props.firebase.database().ref('dmrf/dashboard/' + this.formId + '/' + y).once('value').then(snapshot => {
            if (!snapshot.val()) {
                alert('ไม่มีข้อมูลปี ' + item.props.headerText);
                this.setState({ isLoading: false, year: preYear });
                return;
            }

            this.setState({
                data: snapshot.val(), isLoading: false
            });
        });
    }

    #generateElement = () => {
        const sectionStackTokens = { childrenGap: 10 };
        const wrapStackTokens = { childrenGap: 10 };

        let element;

        switch (this.state.step) {
            case 0:
                element = <div>กำลังอ่านข้อมูล...</div>;
                break;
            case 1:
                element = <div>
                    <YearSelector onLinkClick={this.#changeYear} selectedKey={this.state.year} />
                    <Stack horizontal tokens={sectionStackTokens} verticalAlign={"center"} horizontalAlign={"center"}>
                        <Stack styles={stack1Styles} tokens={wrapStackTokens}>
                            <Overview data={this.state.data} />
                            <Gender data={this.state.data} />
                            <Age data={this.state.data} />
                            <BirthPlace data={this.state.data} />
                            <Hotel data={this.state.data} />
                        </Stack>
                        <Stack styles={stack2Styles} tokens={wrapStackTokens}>
                            <Objective data={this.state.data} />
                            <Factor data={this.state.data} />
                            <ComeBack data={this.state.data} />
                            <Suggest data={this.state.data} />
                        </Stack>
                        <Stack styles={stack2Styles} tokens={wrapStackTokens}>
                            <Rate data={this.state.data} />
                        </Stack>
                    </Stack>
                </div>;
                break;
            case 2:
                element = <div>ไม่พบข้อมูล</div>;
                break;
            default:
                element = <div></div>;
        }

        return element;
    }

    render() {

        let element = this.#generateElement();

        return (
            <>
                <img src={background} alt="bg" className="bg"></img>
                <div style={{
                    // backgroundColor: 'yellow',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3,1fr)',
                    alignItems: 'center',
                    height: '100vh',
                    overflow: 'auto',
                }}>
                    <div></div>
                    <div style={{
                        alignSelf: 'center',
                        // backgroundColor: 'red',
                        // width: '1500px',
                        // textAlign: 'right',
                    }}>
                        {/* <Modal isOpen={true} isAlert={true} onDismiss={() => { alert()}}> hello </Modal> */}
                        <Stack styles={stackStyles} verticalAlign={"center"} horizontalAlign={"center"}>
                            <Stack.Item styles={stackItemStyles}>
                                <Wrapper>
                                    <SpinnerComponent loading={this.state.isLoading} position="global" />
                                    {element}
                                </Wrapper>
                            </Stack.Item>
                        </Stack>
                    </div>
                </div>
            </>
        );
    }
}

function YearSelector(props) {
    let year = new Date().getFullYear();
    let element = [];

    for (let i = year; i >= 2021; i--) {
        element.push(<PivotItem key={i} itemKey={i} headerText={i + 543} />);
    }

    return (
        <Pivot overflowBehavior='menu' selectedKey={props.selectedKey} onLinkClick={props.onLinkClick}>
            <PivotItem key={1} itemKey={1} headerText="ทั้งหมด" />
            {element}
        </Pivot>
    );
}

export default Dashboard;