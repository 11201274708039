import React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '90px',
    // minWidth: '600px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    height: 40px;
`;

// const data1 = [
//     {
//         "key": "กลับมาเที่ยวซ้ำอีกครั้ง",
//         "กลับมาเที่ยวซ้ำอีกครั้ง": 92.78,
//     },
// ];

const colors = {
    'กลับมาเที่ยวซ้ำ': '#b0cff5'
}
const getColor = bar => colors[bar.id]


class ComeBack extends React.Component {

    render() {
        let keyList = this.props.data[83].key; 
        let valueList = this.props.data[83].value;
        let data = {};
        let total = 0;
        let counter = [];

        keyList.forEach((element, index) => {
            if(element.length === 0){
                return;
            }

            total += valueList[index]
            data[`${element}`] = valueList[index];
        });

        Object.keys(data).forEach(element => {
            let value = data[element];
            counter[element] = value;
            value = (value*100)/total;
            data[element] = value.toFixed(1);
        });

        data.key = 'กลับมาเที่ยวซ้ำอีกครั้ง';
        data = [data];

        return (
            <div>
                <Title>การกลับมาท่องเที่ยวในพื้นที่นาเกลือในอนาคต</Title>
                <div style={itemStyles}>
                    <ResponsiveBar
                        data={data}
                        maxValue={100}
                        keys={['กลับมาเที่ยวซ้ำ']}
                        indexBy="key"
                        margin={{ top: 10, right: 50, bottom: 40, left: 120 }}
                        padding={0.3}
                        layout="horizontal"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={d => `${d}%`}
                        colors={getColor}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 1,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: '*',
                                id: 'dots'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        label={d => `${d.formattedValue}`}
                        tooltipLabel={d => `${d.id} ${counter[d.id]} คน `}
                        // tooltipLabel={d => `${d.id}`}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        labelTextColor="black"
                    />
                </div>
            </div>
        );
    }
}

export default ComeBack;