import React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '240px',
    // minWidth: '600px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    height: 40px;
`;

// const data = [
//     {
//         "key": "การเดินทางมาท่องเที่ยวที่สะดวกรวดเร็ว",
//         "การเดินทางมาท่องเที่ยวที่สะดวกรวดเร็ว": 14.88,
//         "count": "68"
//     },
//     {
//         "key": "ความสวยงามและความน่าสนใจของแหล่งท่องเที่ยว",
//         "ความสวยงามและความน่าสนใจของแหล่งท่องเที่ยว": 27.35,
//         "count": "125"
//     },
//     {
//         "key": "มีอาหารที่อร่อยและมีความเฉพาะถิ่นคุ้มค่าต่อการมาท่องเที่ยว",
//         "มีอาหารที่อร่อยและมีความเฉพาะถิ่นคุ้มค่าต่อการมาท่องเที่ยว": 42.67,
//         "count": "195"
//     },
// ];

// const colors = {
//     'มีอาหารที่อร่อยและมีความเฉพาะถิ่นคุ้มค่าต่อการมาท่องเที่ยว': '#ffc8b0',
//     'ความสวยงามและความน่าสนใจของแหล่งท่องเที่ยว': '#ffc8b0',
//     'การเดินทางมาท่องเที่ยวที่สะดวกรวดเร็ว': '#ffc8b0'
// }
// const getColor = bar => colors[bar.id]


class Factor extends React.Component {

    render() {
        let keyList = this.props.data[15].key;
        let valueList = this.props.data[15].value;
        let responseSorted = Object.keys(keyList).sort(function (a, b) { return valueList[a] - valueList[b] });
        let totalResponse = 0;
        let responseList = [];

        responseSorted.forEach((value, index) => {
            if(keyList[value].length === 0) {
                return;
            }

            let responseKey = keyList[value];
            let responseValue = valueList[value];
            let response = {};

            response.key = responseKey;
            response.count = responseValue;
            response[responseKey] = 100;

            responseList.push(response);
            totalResponse += responseValue;
        });


        let keys = [];
        let maxValue = 0;
        responseList.forEach((response, index) => {
            let responsePercent = (100 * response.count) / totalResponse;
            response[response.key] = responsePercent.toFixed(1);
            keys.push(response.key);
            maxValue = response[response.key] > maxValue ? parseFloat(response[response.key]) : maxValue;
        });

        responseList = responseList.slice(-3);
        keys = keys.slice(-3);

        maxValue += 5;
        maxValue = maxValue >= 100 ? 100 : maxValue;
        
        return (
            <div>
                <Title>ปัจจัยสำคัญที่เลือกเดินทางมาท่องเที่ยวในพื้นที่นาเกลือ</Title>
                <div style={itemStyles}>
                    <ResponsiveBar
                        // data={data}
                        data={responseList}
                        // maxValue={45}
                        maxValue={maxValue}
                        // keys={['มีอาหารที่อร่อยและมีความเฉพาะถิ่นคุ้มค่าต่อการมาท่องเที่ยว', 'ความสวยงามและความน่าสนใจของแหล่งท่องเที่ยว', 'การเดินทางมาท่องเที่ยวที่สะดวกรวดเร็ว']}
                        keys={keys}
                        indexBy="key"
                        margin={{ top: 10, right: 50, bottom: 40, left: 300 }}
                        padding={0.3}
                        layout="horizontal"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        // valueFormat={{ format: '', enabled: false }}
                        valueFormat={d => `${d}%`}
                        colors="#ffc8b0"
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 1,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: '*',
                                id: 'dots'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        tooltipLabel={d => `${d.id} ${d.data.count} คน `}
                        // label={d => `${d.value}% / ${d.data.count} คน`}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        labelTextColor="black"
                    />
                </div>
            </div>
        );
    }
}

export default Factor;