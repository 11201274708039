import React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '90px',
    // minWidth: '400px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    height: 40px;
`;

// const data = [
//     {
//         "key": "birthplace",
//         "ในประเทศ": 100,
//     }
// ];

const colors = {
    'ในประเทศ': '#e0b672'
}
const getColor = bar => colors[bar.id];


class BirthPlace extends React.Component {

    render() {
        let keyList = this.props.data[7].key; 
        let valueList = this.props.data[7].value;
        let data = {};
        let total = 0;
        let counter = [];

        keyList.forEach((element, index) => {
            total += valueList[index]
            data[`${element}`] = valueList[index];
        });

        Object.keys(data).forEach(element => {
            let value = data[element];
            counter[element] = value;
            value = (value*100)/total;
            data[element] = value.toFixed(1);
        });

        data.key = 'birthplace';
        data = [data];

        return (
            <div>
                <Title>ภูมิลำเนาของผู้ตอบแบบสอบถาม</Title>
                <div style={itemStyles}>
                    <ResponsiveBar
                        data={data}
                        maxValue={100}
                        keys={['ในประเทศ']}
                        indexBy="birthplace"
                        margin={{ top: 10, right: 15, bottom: 40, left: 15 }}
                        padding={0.3}
                        layout="horizontal"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        valueFormat={d => `${d}%`}
                        colors={getColor}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 1,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'ในประเทศ'
                                },
                                id: 'dots'
                            },
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={null}
                        label={d => `${d.id} ${d.formattedValue}`}
                        tooltipLabel={d => `${d.id} ${counter[d.id]} คน `}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        labelTextColor="black"
                    />
                </div>
            </div>
        );
    }
}

export default BirthPlace;