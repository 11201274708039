import React from 'react';
import styled from 'styled-components';
import { ResponsiveBullet } from '@nivo/bullet'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '810px',
    // minWidth: '600px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
`;

class Rate extends React.Component {

    render() {
        const getValue = (data, key) => {
            let keyIndex = data.key.indexOf(key);
            if (keyIndex < 0) {
                return 0;
            }
            return data.value[keyIndex];
        }

        const prepareData = (startId, endId) => {
            let result = [0, 0, 0, 0, 0];
            let i = startId;

            while (i <= endId) {
                result[0] += getValue(this.props.data[i], 'มากที่สุด');
                result[1] += getValue(this.props.data[i], 'มาก');
                result[2] += getValue(this.props.data[i], 'ปานกลาง');
                result[3] += getValue(this.props.data[i], 'น้อย');
                result[4] += getValue(this.props.data[i], 'น้อยที่สุด');
                i++;
            }

            // console.log(result);

            let weight = 5;
            let total = endId - startId + 1;
            result = result.map(x => (x / total) * weight--);
            // console.log(result);

            result = result.reduce((a, b) => a + b, 0);
            // console.log(result);

            result = result / recordCount;
            // console.log(result);

            /// set score
            result = result < 1.81 ? 0.5 : (
                result < 2.61 ? 1.5 : (
                    result < 3.41 ? 2.5 : (
                        result < 4.21 ? 3.5 : 4.5
                    )
                )
            );


            return result;
        }

        let recordCount = this.props.data[29].value.reduce((a, b) => a + b, 0);
        // console.log(recordCount);

        let dataArr = [];
        dataArr[0] = prepareData(29, 32);
        dataArr[1] = prepareData(33, 35);
        dataArr[2] = prepareData(36, 41);
        dataArr[3] = prepareData(42, 45);
        dataArr[4] = prepareData(46, 51);
        dataArr[5] = prepareData(52, 54);
        dataArr[6] = prepareData(55, 59);
        dataArr[7] = prepareData(60, 63);
        dataArr[8] = prepareData(64, 66);
        dataArr[9] = prepareData(67, 70);


        // console.log(dataArr);

        const data = [
            {
                "id": "1.สิ่งที่ดึงดูดใจทางการท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[0]]
            },
            {
                "id": "2.การเข้าถึงแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[1]]
            },
            {
                "id": "3.สิ่งอำนวยความสะดวกในแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[2]]
            },
            {
                "id": "4.การบริหารจัดการแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[3]]
            },
            {
                "id": "5.ที่พักในแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[4]]
            },
            {
                "id": "6.กิจกรรมการท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[5]]
            },
            {
                "id": "7.ความปลอดภัยในแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[6]]
            },
            {
                "id": "8.การประชาสัมพันธ์แหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[7]]
            },
            {
                "id": "9.ทัศนียภาพแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[8]]
            },
            {
                "id": "10.การบริหารจัดการด้านสิ่งแวดล้อมของแหล่งท่องเที่ยว",
                "ranges": [1, 2, 3, 4, 5],
                "measures": [0],
                "markers": [dataArr[9]]
            },
        ];

        const theme = {
            "axis": {
                "ticks": {
                    "line": {
                        "strokeWidth": 0
                    },
                    "text": {
                        "fontSize": 0
                    }
                }
            },
        "tooltip": {
            "container": {
                "background": "#c62424",
                    "color": "#333333",
                        "fontSize": 0
            },
            "basic": { },
            "chip": { },
            "table": { },
            "tableCell": { },
            "tableCellValue": { }
        }
    };

    return(
            <div>
                <Title>ระดับความพึงพอใจของนักท่องเที่ยวต่อแหล่งท่องเที่ยวพื้นที่นาเกลือ</Title>
                <div style={itemStyles}>
                    <ResponsiveBullet
                        data={data}
                        margin={{ top: 25, right: 40, bottom: 40, left: 280 }}
                        spacing={46}
                        titleAlign="start"
                        titleOffsetX={-265}
                        markerColors={["black"]}
                        measureSize={0.2}
                        markerSize={0.7}
                        rangeColors={["#C1252C", "#DC5D27", "#F79321", "#8CA349", "#21B472"]}
                        theme={theme}
                        isInteractive={false}
                    />
                </div>
            </div >
        );
    }
}

export default Rate;