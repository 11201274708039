import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
import 'firebase/database';
import 'firebase/app-check';
import firebaseConfig from './firebase_config';
import Form from './Form';
import Dashboard from './dashboard';
// import logo from './logo.svg';
// import './App.css';
import "bootstrap/dist/css/bootstrap.css";

initializeIcons();
firebase.initializeApp(firebaseConfig);

if (firebaseConfig.useEmulator === true) {
  firebase.database().useEmulator("localhost", 9000);
} else {
  const appCheck = firebase.appCheck();
  appCheck.activate(
    '6Ld9IDMfAAAAAAQWICRFeeBCPpBImpug2_D4hqb-',
    true);
}


function App() {
  return (
    <Router basename={'/app'}>
      <div className="App">
        <Switch>
          <Route path="/form/:id/:postId"
            // component={Form} loadFormSetting={loadFormSetting}
            render={props => (
              <Form {...props} firebase={firebase} />
            )}
          />
          <Route path="/form/:id"
            // component={Form} loadFormSetting={loadFormSetting}
            render={props => (
              <Form {...props} firebase={firebase} />
            )}
          />
          {/* <Route path="/dashboard/:id"
            render={props => (
              <Dashboard {...props} firebase={firebase} />
            )}
          /> */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
