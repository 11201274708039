const firebaseConfig = {
  apiKey: "AIzaSyDwfjoLKOtKfcrHmWbH0WtZAgC0VNeAaqQ",
  authDomain: "dmrf-ace2b.firebaseapp.com",
  databaseURL: "https://dmrf-ace2b-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dmrf-ace2b",
  storageBucket: "dmrf-ace2b.appspot.com",
  messagingSenderId: "626604437366",
  appId: "1:626604437366:web:4286bd36e3705742329457",
  measurementId: "G-TMF3773VMM",
  useEmulator: false
};

export default firebaseConfig;