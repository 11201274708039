import React from 'react';
import styled from 'styled-components';
import { ResponsiveBar } from '@nivo/bar'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '240px',
    // minWidth: '600px',
    justifyContent: 'center',
};


const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    height: 40px;
`;

// const data = [
//     {
//         "objective": "ซื้ออาหารทะเล",
//         "ซื้ออาหารทะเล": 20.35,
//         "ซื้ออาหารทะเลColor": "hsl(0, 100%, 53%)",
//         "count": "78"
//     },
//     {
//         "objective": "รับประทานอาหาร (อาหารทะเล / ก๋วยเตี๋ยว)",
//         "รับประทานอาหาร (อาหารทะเล / ก๋วยเตี๋ยว)": 21.66,
//         "Color": "hsl(319, 70%, 50%)",
//         "count": "93"
//     },
//     {
//         "objective": "พักผ่อน / ท่องเที่ยว",
//         "พักผ่อน / ท่องเที่ยว": 31.95,
//         "พักผ่อน / ท่องเที่ยวColor": "hsl(319, 70%, 50%)",
//         "count": "146"
//     },
// ];

// const colors = { 'ซื้ออาหารทะเล': '#93db97', 'รับประทานอาหาร (อาหารทะเล / ก๋วยเตี๋ยว)': '#93db97', 'พักผ่อน / ท่องเที่ยว': '#93db97' }
// const getColor = bar => colors[bar.id]


class Objective extends React.Component {

    render() {
        let keyList = this.props.data[13].key;
        let valueList = this.props.data[13].value;
        let responseSorted = Object.keys(keyList).sort(function (a, b) { return valueList[a] - valueList[b] });
        let totalResponse = 0;
        let responseList = [];

        responseSorted.forEach((value, index) => {
            if(keyList[value].length === 0) {
                return;
            }

            let responseKey = keyList[value];
            let responseValue = valueList[value];
            let response = {};

            response.key = responseKey;
            response.count = responseValue;
            response[responseKey] = 100;

            responseList.push(response);
            totalResponse += responseValue;
        });


        let keys = [];
        let maxValue = 0;
        responseList.forEach((response, index) => {
            let responsePercent = (100 * response.count) / totalResponse;
            response[response.key] = responsePercent.toFixed(1);
            keys.push(response.key);
            maxValue = response[response.key] > maxValue ? parseFloat(response[response.key]) : maxValue;
        });

        responseList = responseList.slice(-3);
        keys = keys.slice(-3);

        maxValue += 5;
        maxValue = maxValue >= 100 ? 100 : maxValue;

        return (
            <div>
                <Title>วัตถุประสงค์ของการเดินทางมาท่องเที่ยวในพื้นที่นาเกลือ</Title>
                <div style={itemStyles}>
                    <ResponsiveBar
                        // data={data}
                        data={responseList}
                        // maxValue={45}
                        maxValue={maxValue}
                        // keys={['พักผ่อน / ท่องเที่ยว', 'รับประทานอาหาร (อาหารทะเล / ก๋วยเตี๋ยว)', 'ซื้ออาหารทะเล']}
                        keys={keys}
                        // indexBy="objective"
                        indexBy="key"
                        margin={{ top: 10, right: 50, bottom: 40, left: 220 }}
                        padding={0.3}
                        layout="horizontal"
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        // valueFormat={{ format: '', enabled: false }}
                        valueFormat={d => `${d}%`}
                        // colors={{ scheme: 'nivo' }}
                        // colors={getColor}
                        colors="#93db97"
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 1,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: '*',
                                id: 'dots'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        tooltipLabel={d => `${d.id} ${d.data.count} คน `}
                        // label={d => `${d.value}% / ${d.data.count} คน`}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        labelTextColor="black"
                    />
                </div>
            </div>
        );
    }
}

export default Objective;