import React from 'react';
import styled from 'styled-components';
import { ResponsivePie } from '@nivo/pie'

const itemStyles = {
    alignItems: 'center',
    background: '#f2f2f2',
    height: '240px',
    // minWidth: '400px',
    justifyContent: 'center',
};

const Title = styled.div`
    background-color: #f2f2f2;
    // width: 100%;
    text-align: center;
    padding-top: 20px;
    font-weight: bold;
    height: 40px;
`;

const TotalCount = styled.div`
    margin-top: -60px;
    margin-left: 15px;
`;


class Age extends React.Component {

    render() {
        let keyList = this.props.data[1].key; 
        let valueList = this.props.data[1].value;
        let dataCount= [0,0,0,0,0,0];
        let total = 0;
        // let counter = [];

        // console.log(keyList);
        // console.log(valueList);

        keyList.forEach((element, index) => {
            if(element.length === 0) {
                return;
            }
            total += valueList[index];
            let age = parseInt(element);
            if(age <= 25){
                dataCount[0] += valueList[index];
            }else if(age >= 26 && age <= 43){
                dataCount[1] += valueList[index];
            }else if(age >= 44 && age <= 58){
                dataCount[2] += valueList[index];
            }else if(age >= 59 && age <= 77){
                dataCount[3] += valueList[index];
            }else if(age >= 78 && age <= 101){
                dataCount[4] += valueList[index];
            }else{
                dataCount[5] += valueList[index];
            }
        });


        // console.log(total);
        // console.log(dataCount);

        const data = [
            {
                "id": "Gen Z (อายุต่ำกว่าหรือเท่ากับ 25 ปี): 36 คน ",
                "label": "Gen Z (อายุต่ำกว่าหรือเท่ากับ 25 ปี)",
                "value": (dataCount[0] * 100/total).toFixed(1), //7.88,
                "color": "hsl(24, 70%, 50%)",
            },
            {
                "id": "Gen Y (อายุ 26-43 ปี) : 254 คน ",
                "label": "Gen Y (อายุ 26-43 ปี)",
                "value": (dataCount[1] * 100/total).toFixed(1),//55.58,
                "color": "hsl(298, 70%, 50%)",
            },
            {
                "id": "Gen X (อายุ 44-58 ปี) : 137 คน ",
                "label": "Gen X (อายุ 44-58 ปี)",
                "value": (dataCount[2] * 100/total).toFixed(1),//29.98,
                "color": "hsl(225, 70%, 50%)",
            },
            {
                "id": "Baby Boomer (อายุ 59-77 ปี) : 29 คน ",
                "label": "Baby Boomer (อายุ 59-77 ปี)",
                "value": (dataCount[3] * 100/total).toFixed(1),//6.35,
                "color": "hsl(213, 70%, 50%)",
            },
            {
                "id": "Silent Generation (อายุ 78-101 ปี) : 1 คน ",
                "label": "Silent Generation (อายุ 78-101 ปี)",
                "value": (dataCount[4] * 100/total).toFixed(1),//0.21,
                "color": "hsl(138, 70%, 50%)",
                "count": "1"
            }
        
        ];

        return (
            <div>
                <Title>กลุ่มอายุของผู้ตอบแบบสอบถาม</Title>
                <div style={itemStyles}>
                    <ResponsivePie
                        data={data}
                        sortByValue={true}
                        margin={{ top: 30, right: -200, bottom: 40, left: 15 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        enableArcLinkLabels={false}
                        arcLabel={function (e) { return e.value + "%" }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 1,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: '*',
                                id: 'dots'
                            },
                        ]} legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                justify: false,
                                translateX: 10,
                                translateY: 10,
                                itemsSpacing: 6,
                                itemWidth: 81,
                                itemHeight: 16,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 13,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000'
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                    <TotalCount><h3>{total} คน</h3></TotalCount>
                </div>
            </div>
        );
    }
}

export default Age;