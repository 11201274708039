import React from 'react';
import { Helmet } from 'react-helmet';
import * as Survey from "survey-react";
import * as widgets from "surveyjs-widgets";
import "survey-react/survey.css";
import "survey-react/modern.css";

import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";

import "jquery-bar-rating/dist/themes/css-stars.css";

import $ from "jquery";
import "jquery-ui/ui/widgets/datepicker.js";
import "select2/dist/js/select2.js";
import "jquery-bar-rating";

import "pretty-checkbox/dist/pretty-checkbox.css";

import background from "./bg.jpg";



// import { json } from "./survey_json.js";

//import "icheck/skins/square/blue.css";
window["$"] = window["jQuery"] = $;
//require("icheck");

export { MyQuestion } from "./MyQuestion";

// Survey.StylesManager.applyTheme("default");
Survey.StylesManager.applyTheme("modern");

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.formId = this.props.match.params.id;
        this.postId = this.props.match.params.postId;
        this.model = null;

        this.state = {
            formSetting: ''
        };

        this.loadFormSetting = this.loadFormSetting.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);
        this.onComplete = this.onComplete.bind(this);
        this.onAfterRenderPage = this.onAfterRenderPage.bind(this);
        this.onCurrentPageChanging = this.onCurrentPageChanging.bind(this);
        this.onCurrentPageChanged = this.onCurrentPageChanged.bind(this);
        this.onAfterRenderPanel = this.onAfterRenderPanel.bind(this);
        this.onPanelVisibleChanged = this.onPanelVisibleChanged.bind(this);
    }

    componentDidMount() {
        this.loadFormSetting();
    }

    onValueChanged(result) {
    }

    onComplete(result, options) {
        if (this.postId) {
            return;
        }

        let data = result.data
        let timestamp = Date.now()

        data['timestamp'] = timestamp
        data = JSON.stringify(data);

        options.showDataSaving('กำลังบันทึกข้อมูล...');
        this.saveFormResult(
            data,
            error => {
                if (error) {
                    options.showDataSavingError(' ไม่สามารถทำการบันทึกข้อมูล, กรุณาลองใหม่อีกครั้ง ');
                } else {
                    options.showDataSavingClear();
                }
            });
    }

    onPanelVisibleChanged(sender, options) {
        if (options.visible) {
            this.#setVisible(options.panel.elements);
        } else {
            this.#clearValue(options.panel.elements);
        }
    }

    onAfterRenderPanel(sender, options) {
        this.#runVisibleExpression(options.panel.elements, sender);
    }

    onAfterRenderPage(sender, options) {
        this.#runVisibleExpression(options.page.elements, sender);
    }

    onCurrentPageChanging(sender, options) {
        if (options.isPrevPage || options.isNextPage) {
            this.#setVisible(options.newCurrentPage.elementsValue);
        }
    }

    onCurrentPageChanged(sender, options) {
    }

    loadFormSetting() {
        if (this.postId) {
            this.setState({
                formSetting: {
                    surveyId: this.formId,
                    surveyPostId: this.postId
                }
            });
            return;
        }

        let formSettingRef = this.props.firebase.database().ref('dmrf/elements/' + this.formId);
        formSettingRef.on('value', (snapshot) => {
            const formSetting = snapshot.val();

            this.setState({
                formSetting: formSetting
            });
        });
    };

    saveFormResult(result, callback) {
        this.props.firebase.database().ref('dmrf/responses/' + this.formId).push(
            result, callback);
    }

    #setVisible = (elements) => {
        elements.forEach(element => {
            element.visible = true;
            if (element.elements) {
                this.#setVisible(element.elements);
            }
        });
    }

    #clearValue = (elements) => {
        elements.forEach(element => {
            // element.clearValue();
            element.clearIncorrectValues();
        });
    }

    #runVisibleExpression = (elements, survey) => {
        let _survey = survey;
        elements.forEach(element => {
            element.visible = _survey.runExpression(element.visibleIf);
        });
    }

    render() {
        let title;
        let formElement = null;

        if (this.state.formSetting) {
            this.model = new Survey.Model(this.state.formSetting);
            // document.title = this.model.title;
            title = this.model.title;
            formElement = (
                <Survey.Survey
                    model={this.model}
                    onComplete={this.onComplete}
                    onValueChanged={this.onValueChanged}
                    onAfterRenderPanel={this.onAfterRenderPanel}
                    onAfterRenderPage={this.onAfterRenderPage}
                    onCurrentPageChanging={this.onCurrentPageChanging}
                    onCurrentPageChanged={this.onCurrentPageChanged}
                    onPanelVisibleChanged={this.onPanelVisibleChanged}
                />
            );
        } else {
            formElement = (
                <div>Loading...</div>
            );
        }

        return (
            <div className="container">
                <img src={background} alt="bg" className="bg"></img>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                {formElement}
            </div>
        );
    }
}

export default Form;